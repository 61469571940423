const ProgressBar = (props: any) => {
    const { bgColor, completed } = props;
    const percent = completed > 2 ? completed : 2;
  
    const containerStyles = {
      height: 10,
      width: '100%',
      backgroundColor: "#FFFFFF",
      borderRadius: 50,
    }
  
    const fillerStyles = {
      height: '100%',
      width: `${percent}%`,
      backgroundColor: bgColor,
      borderRadius: 'inherit',
      textAlign: "right" as "right"
    }
  
    const labelStyles = {
      padding: 5,
      color: 'white',
      fontWeight: 'bold'
    }
  
    return (
      <div style={containerStyles}>
        <div style={fillerStyles}>
          <span style={labelStyles}></span>
        </div>
      </div>
    );
  };
  
  export default ProgressBar;